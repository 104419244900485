import Image from 'next/image';
import FooterBorder from '../../assets/images/footer-border.png'
import Logo from '../../assets/logo/workay-logo.svg'
import WaIcon from '../../assets/icons/wa-icon.png'
import MailIcon from '../../assets/icons/mail-icon.png'
import KominfoIcon from "../../assets/icons/kominfo-icon.svg"
import Link from 'next/link';

const Footer = ({ openModal }) => {
  return (
    <footer className='relative'>
      <div className="md:h-[362px] w-full bg-w-green-100 flex flex-col overflow-hidden">
        <div className='w-[1700px] md:w-full overflow-hidden'>
          <Image layout='responsive' src={FooterBorder} alt='_footer-border' />
        </div>
        <div className='container mx-auto flex px-[38px] pt-[40px] md:pt-0 h-full flex-col md:flex-row md:justify-between md:items-center'>
          <div className='flex flex-col'>
            <div>
              <div className="h-[60px] w-[60px]">
                <Image layout="fixed" src={Logo} alt="_logo" />
              </div>
            </div>
            <div className='md:w-[253px] mt-[35px] md:mt-[16px] md:text-left'>
              <p>Workay adalah platform kesehatan finansial untuk karyawan dan membantu perusahaan mempertahankan pekerja terbaik.</p>
            </div>
          </div>
          <div className='flex flex-col mt-[65px] md:mt-0'>

            <div>
              <p className='font-bold'>Hubungi Kami di :</p>
            </div>
            <div className='flex flex-row gap-x-[16px] mt-[14px]'>
              <div onClick={openModal} className='h-[30px] w-[30px] transition-all hover:scale-110 active:scale-100'>
                <Image layout="responsive" src={WaIcon} alt="_logowa" />
              </div>
              <div className='transition-all h-[30px] w-[35px] hover:scale-110 active:scale-100'>
                <a href='mailto:cs@workay.id' target="_blank" rel='noreferrer'>
                  <Image layout="responsive" src={MailIcon} alt="_logomail" />
                </a>
              </div>
            </div>
          </div>
          <div className='mt-[65px] mb-[40px] md:mb-[0px] md:mt-0 flex flex-col md:items-end'>
            <div className='flex flex-col md:flex-row md:items-center'>
              <div className="h-[60px] w-[60px]">
                <Image layout="fixed" src={KominfoIcon} alt="_logo" />

              </div>
              <div className='w-[200px] text-[12px] mt-[10px] md:mt-0 md:ml-[12px]'>
                <p className='whitespace-pre-line'>Nomor TDPSE:
                  001852.02/DJAI.PSE/08/2022
                </p>
                {/* <div className='mt-[5px]'>
                  <p className='font-bold'>PT DIGITEK MAKMUR PANGESTU</p>
                </div> */}
              </div>
            </div>
            <div className='hidden md:flex w-full text-[14px] justify-center flex-row items-center gap-x-[10px] md:gap-x-[20px] text-w-green-200 font-bold mt-[50px] md:mt-[20px]'>
              <Link href='/privacy'>
                <p className='cursor-pointer hover:scale-105 active:scale-100'>Kebijakan dan privasi</p>
              </Link>
              <div className='h-[15px] w-[1px] bg-w-green-200'></div>
              <Link href='/term'>
                <p className='cursor-pointer hover:scale-105 active:scale-100'>Syarat dan ketentuan</p>
              </Link>
            </div>
          </div>
        </div>
        <div className='flex absolute w-full md:hidden text-[14px] bottom-[50px] left-[50%] -translate-x-[50%] justify-center flex-row items-center gap-x-[10px] md:gap-x-[20px] text-w-green-200 font-bold mt-[50px] md:mt-[20px]'>
          <Link href='/privacy'>
            <p className='cursor-pointer hover:scale-105 active:scale-100'>Kebijakan dan privasi</p>
          </Link>
          <div className='h-[15px] w-[1px] bg-w-green-200'></div>
          <Link href='/term'>
            <p className='cursor-pointer hover:scale-105 active:scale-100'>Syarat dan ketentuan</p>
          </Link>
        </div>
        <div className='w-screen h-[32px] bg-w-green-200 mt-[50px] md:mt-0'>

        </div>
      </div>
    </footer>
  )
}

export default Footer;