import Image from "next/image";
import Logo from "../../assets/logo/workay-logo.svg";
import BurgerIcon from "../../assets/icons/burger-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link as LinkScroll } from "react-scroll";
import Link from "next/link";
import { Dialog, Transition } from "@headlessui/react";
import LogoPS from "../../assets/icons/icon-png-ps-32.png";
import LogoPS42 from "../../assets/icons/icon-png-ps-42.png";
import LogoPSSvg from "../../assets/icons/icon-ps.svg";
import LogoPS42Svg from "../../assets/icons/icon-ps-42.svg";
import { useRouter } from "next/router";

const Navbar = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [stateBanner, setStateBanner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const refModal = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (router.pathname != "/privacy" && router.pathname != "/term") {
        setStateBanner(true);
      }
    }, 800);
  }, [router]);

  return (
    <nav className="sticky z-[9] lg:z-[9999] top-0 nav-shadow font-roboto h-[90px] py-[10px]  md:h-[118px] bg-white px-[25px] md:px-[120px] md:py-[29px] ">
      <div className="container relative  mx-auto flex flex-row justify-between items-center">
        <Link href="/">
          <div className="h-[60px] w-[60px]">
            <Image layout="fixed" src={Logo} alt="_logo" />
          </div>
        </Link>
        <div>
          <div
            onClick={() => setOpen(!open)}
            className="transition-all cursor-pointer hover:scale-110 active:scale-100 h-[40px] w-[40px] flex flex-col justify-center items-center lg:hidden"
          >
            <Image layout="fixed" src={BurgerIcon} alt="_burger" />
          </div>
        </div>
        <div className="hidden absolute -bottom-[1.91rem] right-0 lg:flex flex-row lg:gap-x-[40px] xl:gap-x-[83px]">
          <LinkScroll
            href="/#section-1"
            onSetActive={() => setOpen(false)}
            activeClass="nav-active"
            offset={-120}
            spy={true}
            smooth={true}
            to="section-1"
          >
            <div className="cursor-pointer hover:text-w-green-200">
              <p className="pb-1">Beranda</p>
            </div>
          </LinkScroll>
          <LinkScroll
            href="#section-2"
            onSetActive={() => setOpen(false)}
            activeClass="nav-active"
            offset={-120}
            spy={true}
            smooth={true}
            to="section-2"
          >
            <div className="cursor-pointer hover:text-w-green-200">
              <p className="pb-1">Mengapa pilih Workay?</p>
            </div>
          </LinkScroll>
          <LinkScroll
            href="#section-3"
            onSetActive={() => setOpen(false)}
            activeClass="nav-active"
            offset={-120}
            spy={true}
            smooth={true}
            to="section-3"
          >
            <div className="cursor-pointer hover:text-w-green-200">
              <p className="pb-1">Fitur Produk</p>
            </div>
          </LinkScroll>
          <LinkScroll
            href="#section-4"
            onSetActive={() => setOpen(false)}
            activeClass="nav-active"
            offset={-80}
            spy={true}
            smooth={true}
            to="section-4"
          >
            <div className="cursor-pointer hover:text-w-green-200">
              <p className="pb-1">Gabung Workay</p>
            </div>
          </LinkScroll>
        </div>
        <div className="hidden mb-4 lg:flex -ml-10 flex-row justify-center items-center gap-x-5">
          <a
            href={"https://play.google.com/store/apps/details?id=com.workay"}
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-[100px]">
              <Image layout="responsive" src={LogoPSSvg} alt="_logo" />
            </div>
          </a>
          <button
            onClick={() => {
              setOpen(false);
              openModal();
            }}
            className="bg-w-green-200 rounded-[10px] text-[16px] text-white font-bold py-1 transition-all active:scale-100 focus:outline-none hover:scale-[1.02] px-2"
          >
            Masuk
          </button>
        </div>
      </div>

      <div
        className={`absolute overflow-hidden transition-all ${
          open ? "w-5/6" : "w-0"
        } top-0 right-0 z-[9999] lg:hidden h-screen bg-w-green-200-light`}
      >
        <div
          className={`flex h-full flex-col justify-center items-center ${
            open ? "block" : "hidden"
          }`}
        >
          <div
            onClick={() => setOpen(!open)}
            className={`absolute flex justify-center items-center top-5 right-5 transition-all cursor-pointer hover:scale-110 active:scale-100 h-[40px] w-[40px]`}
          >
            <Image layout="fixed" src={CloseIcon} alt="_logo" />
          </div>
          <div
            className={`text-[20px] transition-all ${
              open ? "-translate-x-0" : "translate-x-[500px]"
            } text-right w-full px-8 flex flex-col items-center gap-y-8 small-screen:gap-y-14`}
          >
            <div className="py-5 grid transition-all mb-5 gap-y-3 sms-screen:mb-10 h-full sms-screen:h-[42px] grid-cols-1 sms-screen:grid-cols-2 gap-x-2">
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.workay"
                }
                target="_blank"
                rel="noreferrer"
                className="w-[124px]"
              >
                <div className="w-[124px] h-[42px]">
                  <Image layout="responsive" src={LogoPS42} alt="_logo" />
                </div>
              </a>
              <div className="sms-screen:w-[124px] w-full">
                <button
                  onClick={() => {
                    setOpen(false);
                    openModal();
                  }}
                  className="bg-w-green-200 h-[42px]  w-[124px] rounded-[10px] text-[16px] text-white font-bold transition-all active:scale-100 focus:outline-none hover:scale-[1.02] px-4"
                >
                  Masuk
                </button>
              </div>
            </div>
            <div className="w-full px-5 flex flex-col gap-y-8 small-screen:gap-y-14">
              <LinkScroll
                href="#section-1"
                onSetActive={() => setOpen(false)}
                activeClass="nav-side-active"
                offset={-120}
                spy={true}
                smooth={true}
                to="section-1"
              >
                <div className="cursor-pointer hover:text-w-green-200">
                  <p className="pb-1">Beranda</p>
                </div>
              </LinkScroll>
              <hr className="nav-hr" />
              <LinkScroll
                href="#section-2"
                onSetActive={() => setOpen(false)}
                activeClass="nav-side-active"
                offset={-100}
                spy={true}
                smooth={true}
                to="section-2"
              >
                <div className="cursor-pointer hover:text-w-green-200">
                  <p>Mengapa pilih Workay?</p>
                </div>
              </LinkScroll>
              <hr className="nav-hr" />
              <LinkScroll
                href="#section-3"
                onSetActive={() => setOpen(false)}
                activeClass="nav-side-active"
                offset={-100}
                spy={true}
                smooth={true}
                to="section-3"
              >
                <div className="cursor-pointer hover:text-w-green-200">
                  <p className="pb-1">Fitur Produk</p>
                </div>
              </LinkScroll>
              <hr className="nav-hr" />
              <LinkScroll
                href="#section-4"
                onSetActive={() => setOpen(false)}
                activeClass="nav-side-active"
                offset={-50}
                spy={true}
                smooth={true}
                to="section-4"
              >
                <div className="cursor-pointer hover:text-w-green-200">
                  <p className="pb-1">Gabung Workay</p>
                </div>
              </LinkScroll>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          stateBanner ? "" : "translate-y-40"
        } transition-all duration-[400ms] fixed bottom-0 left-0 right-0 pt-5  bg-white flex lg:hidden flex-col items-center`}
      >
        <div
          onClick={() => {
            setTimeout(() => {
              setStateBanner(false);
            }, 500);
          }}
          className={`absolute flex justify-center items-center top-2 right-5 transition-all cursor-pointer hover:scale-110 active:scale-100 h-[40px] w-[40px]`}
        >
          <Image layout="fixed" src={CloseIcon} alt="_logo" />
        </div>
        <div className="py-5 grid transition-all mb-5 sms-screen:mb-10 h-full sms-screen:h-[42px] grid-cols-1 sms-screen:grid-cols-2 gap-x-3 gap-y-2">
          <a
            href={"https://play.google.com/store/apps/details?id=com.workay"}
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full">
              <Image layout="responsive" src={LogoPS42} alt="_logo" />
            </div>
          </a>
          <div className="sms-screen:w-[124px] w-full">
            <button
              onClick={() => {
                setOpen(false);
                openModal();
              }}
              className="bg-w-green-200 h-[42px]  w-[124px] rounded-[10px] text-[16px] text-white font-bold transition-all active:scale-100 focus:outline-none hover:scale-[1.02] px-4"
            >
              Masuk
            </button>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          initialFocus={refModal}
          as="div"
          className="relative z-[9999]"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-5 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full font-roboto py-[130px] sm:py-[130px] max-w-3xl transform overflow-hidden rounded-[15px] bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex gap-x-[60px] flex-col justify-center items-center">
                    <div
                      onClick={closeModal}
                      className="w-[42px] h-[42px] flex hover:scale-110 active:scale-100 justify-center items-center  absolute top-5 right-5"
                    >
                      <Image src={CloseIcon} alt="close-icon" />
                    </div>
                    <div
                      className={`absolute bottom-0 left-0 right-0 pt-5 pb-5  bg-w-green-200 flex flex-col items-center`}
                    >
                      <div className="text-white font-roboto items-center flex flex-col sm:flex-row gap-x-3 gap-y-2">
                        <p>
                          Dapatkan aplikasi <strong>Workay di</strong>
                        </p>
                        <a
                          href={
                            "https://play.google.com/store/apps/details?id=com.workay"
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="w-[103px]"
                        >
                          <div className="w-[103px]">
                            <Image
                              layout="responsive"
                              src={LogoPSSvg}
                              alt="_logo"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div>
                      <h1 className="font-bold text-center text-[32px] text-w-text">
                        Anda ingin masuk sebagai:
                      </h1>
                    </div>
                    <div className="mt-10 flex flex-col items-center gap-y-5 w-full">
                      <a
                        href="https://web.workay.id"
                        target="_blank"
                        rel="noreferrer"
                        className="bg-w-green-200 text-center w-[201px] rounded-[10px] text-[16px] text-white font-bold py-3 transition-all active:scale-100 focus:outline-none hover:scale-[1.02] px-4"
                      >
                        Karyawan
                      </a>
                      <a
                        href="https://hr.workay.id"
                        target="_blank"
                        rel="noreferrer"
                        className="w-[201px] rounded-[10px] hover:bg-w-green-100 active:bg-w-green-100 text-center text-[16px] text-w-green-200 font-bold py-3 transition-all active:scale-100 focus:outline-none hover:scale-[1.02] px-4"
                      >
                        Tim HRD
                      </a>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </nav>
  );
};

export default Navbar;
